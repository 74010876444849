define("@woody-lite/addon-crm-campaigns/components/modal-event-digital-payments", ["exports", "@ember/component", "@woody-lite/core-foundation/components/modal", "@ember/application", "@ember/template-factory"], function (_exports, _component, _modal, _application, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <WrapperMain as |wrapper|>
  	<wrapper.header @type={{this.typeModal}} as |header|>
  		<header.title @title={{t "label.digitalPayments.modalTitle"}} />
  		<header.right as |i|>
  			<i.icon @class="icon-close" @onClick={{fn this.close false}} @accessibilityText={{t "label.close"}} />
  		</header.right>
  	</wrapper.header>
  	<wrapper.network />
  	<wrapper.alerter />
  	<wrapper.content>
  		<article>
  			<IframeAem
  				data-id="digitalPaymentsIframe"
  				@src={{this.url}}
  				@class="vh"
  				@allowedDomains={{this.allowedDomains}}
  			/>
  		</article>
  	</wrapper.content>
  </WrapperMain>
  */
  {
    "id": "rhK2i8RD",
    "block": "[[[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n\\t\"],[8,[30,1,[\"header\"]],null,[[\"@type\"],[[30,0,[\"typeModal\"]]]],[[\"default\"],[[[[1,\"\\n\\t\\t\"],[8,[30,2,[\"title\"]],null,[[\"@title\"],[[28,[37,1],[\"label.digitalPayments.modalTitle\"],null]]],null],[1,\"\\n\\t\\t\"],[8,[30,2,[\"right\"]],null,null,[[\"default\"],[[[[1,\"\\n\\t\\t\\t\"],[8,[30,3,[\"icon\"]],null,[[\"@class\",\"@onClick\",\"@accessibilityText\"],[\"icon-close\",[28,[37,2],[[30,0,[\"close\"]],false],null],[28,[37,1],[\"label.close\"],null]]],null],[1,\"\\n\\t\\t\"]],[3]]]]],[1,\"\\n\\t\"]],[2]]]]],[1,\"\\n\\t\"],[8,[30,1,[\"network\"]],null,null,null],[1,\"\\n\\t\"],[8,[30,1,[\"alerter\"]],null,null,null],[1,\"\\n\\t\"],[8,[30,1,[\"content\"]],null,null,[[\"default\"],[[[[1,\"\\n\\t\\t\"],[10,\"article\"],[12],[1,\"\\n\\t\\t\\t\"],[8,[39,4],[[24,\"data-id\",\"digitalPaymentsIframe\"]],[[\"@src\",\"@class\",\"@allowedDomains\"],[[30,0,[\"url\"]],\"vh\",[30,0,[\"allowedDomains\"]]]],null],[1,\"\\n\\t\\t\"],[13],[1,\"\\n\\t\"]],[]]]]],[1,\"\\n\"]],[1]]]]]],[\"wrapper\",\"header\",\"i\"],false,[\"wrapper-main\",\"t\",\"fn\",\"article\",\"iframe-aem\"]]",
    "moduleName": "@woody-lite/addon-crm-campaigns/components/modal-event-digital-payments.hbs",
    "isStrictMode": false
  });
  class ModalEventDigitalPaymentsComponent extends _modal.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "allowedDomains", void 0);
    }
    init() {
      var _config$crmCampaigns, _config$crmCampaigns2, _config$network;
      super.init(...arguments);
      const config = (0, _application.getOwner)(this).resolveRegistration('config:environment');
      this.allowedDomains = config === null || config === void 0 || (_config$crmCampaigns = config['crm-campaigns']) === null || _config$crmCampaigns === void 0 ? void 0 : _config$crmCampaigns.allowedDomains;
      this.urlIframe = config === null || config === void 0 || (_config$crmCampaigns2 = config['crm-campaigns']) === null || _config$crmCampaigns2 === void 0 ? void 0 : _config$crmCampaigns2.urlIframeDigitalPayments;
      this.aemEndPoint = config === null || config === void 0 || (_config$network = config.network) === null || _config$network === void 0 || (_config$network = _config$network.hosts) === null || _config$network === void 0 ? void 0 : _config$network.aem;
    }
    get url() {
      return "".concat(this.aemEndPoint, "/").concat(this.urlIframe);
    }
    get typeModal() {
      var _this$model;
      return ((_this$model = this.model) === null || _this$model === void 0 || (_this$model = _this$model.options) === null || _this$model === void 0 ? void 0 : _this$model.typeModal) || 'modal-primary';
    }
  }
  _exports.default = ModalEventDigitalPaymentsComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ModalEventDigitalPaymentsComponent);
});