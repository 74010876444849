define("@woody-lite/core-session/components/modal-face-recognition", ["exports", "@woody-lite/core-foundation/components/modal", "@ember/service", "@ember/object", "@glimmer/tracking", "@woody-lite/core-session/resources/client-enax", "@woody-lite/core-session/templates/modal-face-recognition"], function (_exports, _modal, _service, _object, _tracking, _clientEnax, _modalFaceRecognition) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const IFRAME_RESTART_EVENTS = ['SelfieRestartClicked'];
  const ALLOW = 'camera; microphone; geolocation; accelerometer; gyroscope;';
  let ModalFaceRecognitionComponent = _exports.default = (_class = class ModalFaceRecognitionComponent extends _modal.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "layout", _modalFaceRecognition.default);
      _defineProperty(this, "allowedDomains", ['https://xpressid-work.eu.veri-das.com']);
      _initializerDefineProperty(this, "veil", _descriptor, this);
      _initializerDefineProperty(this, "slinky", _descriptor2, this);
      _initializerDefineProperty(this, "provider", _descriptor3, this);
      _initializerDefineProperty(this, "modal", _descriptor4, this);
      _initializerDefineProperty(this, "urlFrame", _descriptor5, this);
      _initializerDefineProperty(this, "permissionsRequested", _descriptor6, this);
      _initializerDefineProperty(this, "takingSelfie", _descriptor7, this);
      _initializerDefineProperty(this, "selfieStatus", _descriptor8, this);
      _defineProperty(this, "resource", null);
      _defineProperty(this, "allow", ALLOW);
      this.veil.remove();
    }
    didReceiveAttrs() {
      super.didReceiveAttrs(...arguments);
      this.resource = this.model.options.resource;
      const authenticationresponse = JSON.parse(atob(this.resource.authenticationresponse));
      this.urlFrame = authenticationresponse.urlFrame;
      this.permissionsRequested = false;
      this.veil.remove();
    }
    processEvents(data) {
      var _data$infoEvent;
      if (IFRAME_RESTART_EVENTS.includes(data === null || data === void 0 || (_data$infoEvent = data.infoEvent) === null || _data$infoEvent === void 0 ? void 0 : _data$infoEvent.code)) {
        this.reject({
          chooseAnotherType: true
        });
      }
    }
    async requestPermissions() {
      try {
        const device = await this.provider.pull('device', 'getDevice');
        const isAndroid = device.isAndroid;
        if (isAndroid) {
          const {
            permission: cameraPermission
          } = await this.slinky.send('Device.requestPermission', {
            permission: 'camera'
          });
          const {
            permission: microphonePermission
          } = await this.slinky.send('Device.requestPermission', {
            permission: 'microphone'
          });
          if (!cameraPermission || !microphonePermission) {
            await this.modal.open('face-recognition-error', {
              errorType: 'permissions'
            });
            this.reject();
          }
        }
      } catch {
        this.permissionsRequested = false;
      } finally {
        this.veil.remove();
      }
      this.permissionsRequested = true;
      this.takingSelfie = true;
    }
    async processError() {
      await this.modal.open('face-recognition-error', {
        errorType: 'generic'
      });
      this.reject();
    }
    async processCompleted() {
      this.takingSelfie = false;
      this.selfieStatus = 'success';
    }
    retryWithOtp() {
      this.veil.add();
      this.close({
        selectSignatureType: _clientEnax.SIGNATURE_TYPES.FACE_RECOGNITION
      });
    }
    async processCancelled() {
      await this.modal.open('face-recognition-error', {
        errorType: 'cancelled'
      });
      this.reject();
    }
    closeModal() {
      this.reject();
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "veil", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "slinky", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "provider", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "modal", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "urlFrame", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "permissionsRequested", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "takingSelfie", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "selfieStatus", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'pending';
    }
  }), _applyDecoratedDescriptor(_class.prototype, "processEvents", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "processEvents"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "requestPermissions", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "requestPermissions"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "processError", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "processError"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "processCompleted", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "processCompleted"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "retryWithOtp", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "retryWithOtp"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "processCancelled", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "processCancelled"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeModal", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "closeModal"), _class.prototype), _class);
});