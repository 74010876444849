define("@woody-mrs-potato/addon-ember-alerter/services/alerter", ["exports", "@woody-mrs-potato/addon-ember-alerter/models/alert", "@ember/service", "@ember/array", "@ember/object"], function (_exports, _alert, _service, _array, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class AlerterService extends _service.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "content", (0, _array.A)());
      _defineProperty(this, "views", (0, _array.A)());
    }
    add(alerts) {
      alerts = (0, _array.isArray)(alerts) ? alerts : [alerts];
      alerts = alerts.map(alert => {
        this._assignView(alert);
        return _alert.default.create(alert);
      });
      this.content.addObjects(alerts);
    }
    one() {
      this.add(...arguments);
    }
    clear(key, value) {
      const removed = [];
      let objects = this.content;
      if (typeof key === 'function') {
        objects = this.content.filter(key);
      } else if (typeof key === 'object') {
        objects = (0, _array.isArray)(key) ? key : [key];
      } else if (key && value) {
        objects = this.content.filterBy(key, value);
      }
      objects.forEach(object => {
        if (object !== null && object !== void 0 && object.isShown) {
          (0, _object.set)(object, 'isShown', false);
        } else {
          removed.push(object);
        }
      });
      this.content.removeObjects(removed);
    }
    _assignView(alert) {
      var _this$views$lastObjec;
      alert.view = (_this$views$lastObjec = this.views.lastObject) === null || _this$views$lastObjec === void 0 ? void 0 : _this$views$lastObjec.elementId;
    }
  }
  _exports.default = AlerterService;
});