define("ember-state/services/state", ["exports", "@ember/service", "@ember/object/evented", "@ember/object", "@ember/array", "@ember/application", "@ember/debug", "@ember/utils", "@ember/runloop", "@glimmer/tracking"], function (_exports, _service, _evented, _object, _array, _application, _debug, _utils, _runloop, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const {
    location
  } = window;

  /**
   * State service that manage browser history navigation.
   *
   * When browser navigates it emits events `forward` and `back`.
   */
  let StateService = _exports.default = (_class = class StateService extends _service.default.extend(_evented.default) {
    /**
     * Array with all history states ordered by index.
     */
    get states() {
      return (0, _array.A)(this.content.sortBy('index'));
    }

    /**
     * Pointer to the current state.
     */

    /**
     * Check if service should be enabled.
     */
    get isEnabled() {
      const userAgent = window.navigator.userAgent;
      return !userAgent.match(/CriOS/);
    }

    /**
     * Returns the next state of the history.
     */
    get next() {
      const pointer = this.pointer + 1;
      return this.content.findBy('index', pointer);
    }

    /**
     * Returns the current state of the history.
     */
    get current() {
      const pointer = this.pointer;
      return this.content.findBy('index', pointer);
    }

    /**
     * Returns the previous state of the history.
     */
    get previous() {
      const pointer = this.pointer - 1;
      return this.content.findBy('index', pointer);
    }

    /**
     * Returns the last navigated state of the history.
     */

    /**
     * Initialize the service.
     */
    constructor() {
      super(...arguments);
      /**
       * Array with all history states.
       */
      _initializerDefineProperty(this, "content", _descriptor, this);
      _initializerDefineProperty(this, "pointer", _descriptor2, this);
      _initializerDefineProperty(this, "last", _descriptor3, this);
      if (this.isEnabled) {
        this._updatePointer();
        window.addEventListener('popstate', this._popstateDidChange);
        (0, _application.getOwner)(this).lookup('service:router').on('routeWillChange', this._routeWillChange);
        (0, _application.getOwner)(this).lookup('service:router').on('routeDidChange', this._routeDidChange);
      }
    }

    /**
     * Unbind pop state listener.
     */
    willDestroy() {
      super.willDestroy();
      if (this.isEnabled) {
        window.removeEventListener('popstate', this._popstateDidChange);
        (0, _application.getOwner)(this).lookup('service:router').off('routeWillChange', this._routeWillChange);
        (0, _application.getOwner)(this).lookup('service:router').off('routeDidChange', this._routeDidChange);
      }
    }

    /**
     * Push state to the history.
     *
     * @param {object} state
     * @param {string} title
     * @param {string} uri
     */
    push() {
      let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      let title = arguments.length > 1 ? arguments[1] : undefined;
      let uri = arguments.length > 2 ? arguments[2] : undefined;
      (false && !(typeof state === 'object') && (0, _debug.assert)('state argument must be an object', typeof state === 'object'));
      const current = this.current;
      uri = uri || location.hash;
      this.last = current;
      this.pointer += 1;
      state.index = this.pointer;
      window.history.pushState(state, title, uri);
      this._addContent(state);
      this.trigger('forward', state, current);
    }

    /**
     * Replace current state of the history.
     *
     * @param {object} state
     * @param {string} title
     * @param {string} uri
     */
    replace(state, title, uri) {
      (false && !(typeof state === 'object') && (0, _debug.assert)('state argument must be an object', typeof state === 'object'));
      const current = this.current;
      uri = uri || location.hash;
      state.index = current.index;
      window.history.replaceState(state, title, uri);
      this._addContent(state);
    }

    /**
     * Add state to content removing all newest states.
     *
     * @param {object} state
     * @private
     */
    _addContent(state) {
      let content = this.content;
      content = content.reject(object => object.index >= state.index);
      content.push(state);
      this.content = (0, _array.A)(content);
    }

    /**
     * Manage popstate event.
     *
     * @private
     */
    _popstateDidChange(e) {
      // Prevent popping manual triggered events.
      // istanbul ignore if: unable to test
      if (e.isTrigger) {
        return;
      }
      (0, _runloop.schedule)('routerTransitions', () => {
        if (this.triggerChange) {
          this.triggerChange();
        }
      });

      // eslint-disable-next-line complexity, max-statements
      this.triggerChange = transition => {
        const current = this.current;
        let state = window.history.state;

        // Always save current state as last.
        this.last = current;
        this.triggerChange = null;

        // If last transition is a replace, then do nothing.
        if (transition && transition.urlMethod === 'replace') {
          this._updateState();
          return;
        }
        if ((0, _utils.isNone)(state) || (0, _utils.isNone)(state.index)) {
          this.pointer += 1;
          state = this._updateState();
          this._addContent(state);
          this.trigger('forward', state, current);
          return;
        }

        // istanbul ignore else
        if (current && state.index > current.index) {
          this.pointer += 1;
          this.trigger('forward', state, current);
        } else if (current && state.index < current.index) {
          this.pointer -= 1;
          this.trigger('back', state, current);
        }
      };
    }

    /**
     * Generates a new state in the current pointer.
     *
     * @private
     */
    _updateState() {
      const state = {
        index: this.pointer
      };
      window.history.replaceState(state, null, location.hash);
      return state;
    }

    /**
     * Update pointer to the current history state.
     *
     * @private
     */
    _updatePointer() {
      let state = window.history.state;
      if ((0, _utils.isNone)(state) || (0, _utils.isNone)(state.index)) {
        this.pointer = window.history.length - 1;
        state = this._updateState();
        this._addContent(state);
        return;
      }
      this.pointer = state.index;
      this._addContent(state);
    }

    /**
     * Save transition on will transition.
     *
     * @param {Object} transition
     */
    _routeWillChange(transition) {
      if (this.triggerChange) {
        this.triggerChange(transition);
      }
    }

    /**
     * Save transition on did transition.
     *
     * @param  {Object}             transition
     */
    _routeDidChange(transition) {
      if (this.triggerChange) {
        this.triggerChange(transition);
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "content", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return (0, _array.A)();
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "pointer", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "last", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "_popstateDidChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "_popstateDidChange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_routeWillChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "_routeWillChange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_routeDidChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "_routeDidChange"), _class.prototype), _class);
});