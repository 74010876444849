define("@woody-lite/addon-crm-campaigns/models/event-action", ["exports", "@ember/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class EventActionModel extends _object.default {
    constructor() {
      super(...arguments);
      /**
       * When this actions should be triggered:
       *   - `load`: when the user see the campaign
       *   - `ok`: when the user interacts with the campaign
       *   - `cancel`: when the user dismiss the campaign
       */
      _defineProperty(this, "trigger", 'ok');
      _defineProperty(this, "trace", true);
      _defineProperty(this, "dismissAfterAction", false);
      _defineProperty(this, "inAppScreenData", null);
    }
    get url() {
      return this._url;
    }
    set url(value) {
      if (value && value.includes('?')) {
        let [url] = value.split('?');
        if (url.indexOf('/') === 0) {
          url = url.replace('/', '');
        }
        if (url.indexOf('/') !== -1) {
          url = url.replace(/\//g, '.');
        }
        value = url;
      }
      this._url = value;
    }
  }
  _exports.default = EventActionModel;
});