define("@woody-lite/addon-feedback/components/modal-error-form", ["exports", "@ember/component", "@woody-lite/core-foundation/components/modal", "@ember/template-factory"], function (_exports, _component, _modal, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <WrapperMain as |wrapper|>
  	<wrapper.header @type="modal-primary" as |header|>
  		<header.title @title={{t "label.feedback"}} />
  		<header.right as |i|>
  			<i.icon data-id="btnClose" class="icon-close" @onClick={{this.close}} @accessibilityText={{t "label.close"}} />
  		</header.right>
  	</wrapper.header>
  	<wrapper.content>
  		<Panels::PanelInfo @image={{image-path "banners/alert.svg"}} @title={{t "label.feedbackError.title"}} as |panel|>
  			<panel.text>
  				{{t "label.feedbackError.text"}}
  			</panel.text>
  		</Panels::PanelInfo>
  
  		<div class="txt-align-c set-padding-2">
  			<Buttons::ButtonDefault data-id="btnClose" @text={{t "label.close"}} @onClick={{this.close}} />
  		</div>
  	</wrapper.content>
  </WrapperMain>
  */
  {
    "id": "Wk4E1XV4",
    "block": "[[[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n\\t\"],[8,[30,1,[\"header\"]],null,[[\"@type\"],[\"modal-primary\"]],[[\"default\"],[[[[1,\"\\n\\t\\t\"],[8,[30,2,[\"title\"]],null,[[\"@title\"],[[28,[37,1],[\"label.feedback\"],null]]],null],[1,\"\\n\\t\\t\"],[8,[30,2,[\"right\"]],null,null,[[\"default\"],[[[[1,\"\\n\\t\\t\\t\"],[8,[30,3,[\"icon\"]],[[24,\"data-id\",\"btnClose\"],[24,0,\"icon-close\"]],[[\"@onClick\",\"@accessibilityText\"],[[30,0,[\"close\"]],[28,[37,1],[\"label.close\"],null]]],null],[1,\"\\n\\t\\t\"]],[3]]]]],[1,\"\\n\\t\"]],[2]]]]],[1,\"\\n\\t\"],[8,[30,1,[\"content\"]],null,null,[[\"default\"],[[[[1,\"\\n\\t\\t\"],[8,[39,2],null,[[\"@image\",\"@title\"],[[28,[37,3],[\"banners/alert.svg\"],null],[28,[37,1],[\"label.feedbackError.title\"],null]]],[[\"default\"],[[[[1,\"\\n\\t\\t\\t\"],[8,[30,4,[\"text\"]],null,null,[[\"default\"],[[[[1,\"\\n\\t\\t\\t\\t\"],[1,[28,[35,1],[\"label.feedbackError.text\"],null]],[1,\"\\n\\t\\t\\t\"]],[]]]]],[1,\"\\n\\t\\t\"]],[4]]]]],[1,\"\\n\\n\\t\\t\"],[10,0],[14,0,\"txt-align-c set-padding-2\"],[12],[1,\"\\n\\t\\t\\t\"],[8,[39,5],[[24,\"data-id\",\"btnClose\"]],[[\"@text\",\"@onClick\"],[[28,[37,1],[\"label.close\"],null],[30,0,[\"close\"]]]],null],[1,\"\\n\\t\\t\"],[13],[1,\"\\n\\t\"]],[]]]]],[1,\"\\n\"]],[1]]]]]],[\"wrapper\",\"header\",\"i\",\"panel\"],false,[\"wrapper-main\",\"t\",\"panels/panel-info\",\"image-path\",\"div\",\"buttons/button-default\"]]",
    "moduleName": "@woody-lite/addon-feedback/components/modal-error-form.hbs",
    "isStrictMode": false
  });
  class ModalErrorFormComponent extends _modal.default {}
  _exports.default = ModalErrorFormComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ModalErrorFormComponent);
});