define("@woody-lite/core-session/services/contact-id", ["exports", "@ember/service", "@ember/object", "@woody-lite/core-session/decorators/session"], function (_exports, _service, _object, _session) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ContactIdService = _exports.default = (_dec = (0, _session.default)('public'), _dec2 = (0, _session.default)('client'), _class = class ContactIdService extends _service.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "slinky", _descriptor, this);
      _initializerDefineProperty(this, "publicCurrentSession", _descriptor2, this);
      _initializerDefineProperty(this, "privateCurrentSession", _descriptor3, this);
    }
    async getContactId() {
      var _window$HtmlSessionId;
      if (this.slinky.isConnected) {
        const applicationData = await this.slinky.send('Device.getApplicationData');
        return (0, _object.get)(applicationData, 'contactId');
      }
      if (this.privateCurrentSession) {
        return (0, _object.get)(this.privateCurrentSession, 'contactId');
      }
      if (this.publicCurrentSession) {
        return (0, _object.get)(this.publicCurrentSession, 'contactId');
      }
      return (_window$HtmlSessionId = window.HtmlSessionId) === null || _window$HtmlSessionId === void 0 ? void 0 : _window$HtmlSessionId.getId();
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "slinky", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "publicCurrentSession", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "privateCurrentSession", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
});