define("@woody-mrs-potato/addon-vertical-collection/components/memory-scroll", ["exports", "@ember/component", "@ember/service"], function (_exports, _component, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let MemoryScrollComponent = _exports.default = (_class = class MemoryScrollComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "memoryScroll", _descriptor, this);
    }
    didRender() {
      super.didRender(...arguments);
      const key = this.key;
      if (!key) {
        throw new Error('You must provide a key to memory-scroll like <MemoryScroll @key="this.my-awesome-pane" />.');
      }
      if (key !== this._lastKey) {
        this.remember(this._lastKey);
        this._lastKey = key;
        this.restore(key);
      }
    }
    willDestroyElement() {
      super.willDestroyElement(...arguments);
      this.remember(this.key);
    }
    remember(key) {
      if (key) {
        if (this.hasDelay) {
          requestAnimationFrame(() => {
            this._updateScroll(key);
          });
        } else {
          this._updateScroll(key);
        }
      }
    }
    restore(key) {
      const position = this.memoryScroll[key] || 0;
      const elt = this.targetElement();
      if (elt) {
        elt.scrollTop = position;
      }
    }
    targetElement() {
      return this.element;
    }
    _updateScroll(key) {
      var _this$targetElement;
      const position = (_this$targetElement = this.targetElement()) === null || _this$targetElement === void 0 ? void 0 : _this$targetElement.scrollTop;
      if (position >= 0) {
        this.memoryScroll[key] = position;
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "memoryScroll", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
});