define("@woody-lite/core-session/repositories/session", ["exports", "@woody-mrs-potato/addon-ember-object-data-model/repositories/repository", "ember-utils/utils/hash/guid", "@ember/service", "@ember/application", "@ember/debug", "@woody-lite/core-session/models/session/base", "@woody-lite/core-session/errors/login-go-on", "@ember/object", "ember-utils/utils/banking/identification-unformatter", "@glimmer/tracking"], function (_exports, _repository, _guid, _service, _application, _debug, _base, _loginGoOn, _object, _identificationUnformatter, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const AUTHENTICATION_TYPES = {
    password: '02',
    emulation: '20',
    sso: '27',
    public_tsec: '61',
    authTypePrivate: '244'
  };
  const NATIVE_APPS = {
    WOODY: 'WOODY',
    NO_CLIENT: 'NO_CLIENT'
  };
  const NATIVE_SCOPES = {
    PUBLIC: 'PUBLIC',
    PRIVATE: 'PRIVATE'
  };
  const NATIVE_PAIRS = {
    client: {
      app: NATIVE_APPS.WOODY,
      scope: NATIVE_SCOPES.PRIVATE
    },
    public: {
      app: NATIVE_SCOPES.PUBLIC
    }
  };
  const MODELS_BY_APPS = {
    public: 'session/public'
  };
  const getModelByApp = app => MODELS_BY_APPS[app] || app;
  let SessionRepository = _exports.default = (_class = class SessionRepository extends _repository.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "enax", _descriptor, this);
      _initializerDefineProperty(this, "globalContext", _descriptor2, this);
      _initializerDefineProperty(this, "intl", _descriptor3, this);
      _initializerDefineProperty(this, "nativeStorage", _descriptor4, this);
      _initializerDefineProperty(this, "provider", _descriptor5, this);
      _initializerDefineProperty(this, "router", _descriptor6, this);
      _initializerDefineProperty(this, "sessionStorage", _descriptor7, this);
      _initializerDefineProperty(this, "slinky", _descriptor8, this);
      _initializerDefineProperty(this, "contactId", _descriptor9, this);
      _initializerDefineProperty(this, "retry", _descriptor10, this);
    }
    _getContactId() {
      return this.contactId.getContactId();
    }
    async getContactId() {
      let {
        contactId
      } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      const contactIdNew = await this._getContactId();
      return {
        ContactId: contactId || contactIdNew
      };
    }
    async nativeGTSession(userData, contactId) {
      let authenticationType = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : AUTHENTICATION_TYPES.password;
      const {
        userId,
        password,
        app
      } = userData;
      const data = {
        scope: NATIVE_SCOPES.PRIVATE,
        app: NATIVE_APPS.WOODY,
        userId,
        password,
        authenticationType
      };
      await this.slinky.send('Session.invokeGrantingTicket', data);
      const session = await this.slinky.send('Session.getData');
      const {
        ContactId: contact
      } = await this.getContactId({
        contactId
      });
      const response = await this.slinky.send('Session.getGrantingTicketResponse');
      const {
        headers: {
          tsec
        },
        result: {
          user
        }
      } = (0, _object.getProperties)(response, 'headers', 'result');
      return this._createSessionModel(app, userId, Object.assign({
        app,
        consumerId: (0, _object.get)(session, 'aap'),
        tsec,
        contactId: contact,
        username: user.alias
      }, user));
    }
    async loginWithPassword(_ref, contactId) {
      let {
        username,
        password,
        app,
        akamaiEnding
      } = _ref;
      let authenticationType = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : AUTHENTICATION_TYPES.password;
      const {
        consumerId
      } = this._getConfig(app);
      const payload = {
        authentication: {
          consumerID: consumerId,
          authenticationData: [{
            authenticationData: [password],
            idAuthenticationData: 'password'
          }],
          authenticationType,
          userID: username.toUpperCase()
        }
      };
      const contactIdHeader = await this.getContactId({
        contactId
      });
      const loginHeaders = {
        ...contactIdHeader,
        'x-cau': akamaiEnding
      };
      const agent = await this.enax.POST('granting-tickets', payload, {
        headers: loginHeaders
      });
      const {
        data,
        response: {
          headers
        }
      } = agent;
      const tsec = headers.get('tsec');
      if (!tsec) {
        const params = {
          authenticationData: headers.get('authenticationdata'),
          authenticationState: data.authenticationState,
          authenticationType: headers.get('authenticationtype'),
          consumerId,
          multistepProcessId: data.multistepProcessId,
          username
        };
        throw new _loginGoOn.default(params);
      }
      const model = this._createSessionModel(app, data.user.id, Object.assign({
        app,
        consumerId,
        tsec,
        username,
        contactId: contactIdHeader.ContactId,
        authenticationType
      }, data.user));
      (false && !(model instanceof _base.default) && (0, _debug.assert)("Model ".concat(app, " must extend 'SessionBaseModel' from @woody-lite/core-session"), model instanceof _base.default));
      return model;
    }
    async loginWithOtp(_ref2) {
      let {
        authenticationType,
        username,
        multistepProcessId,
        otp,
        app,
        consumerId: loginConsumerId
      } = _ref2;
      const {
        consumerId
      } = this._getConfig(app);
      const payload = {
        authentication: {
          consumerID: loginConsumerId ? loginConsumerId : consumerId,
          authenticationData: otp ? [{
            authenticationData: [otp],
            idAuthenticationData: 'otp'
          }] : null,
          authenticationType,
          userID: username.toUpperCase(),
          multistepProcessId
        }
      };
      const contactIdHeader = await this.getContactId();
      const agent = await this.enax.POST('granting-tickets', payload, {
        headers: contactIdHeader
      });
      const {
        data,
        response: {
          headers
        }
      } = agent;
      const tsec = headers.get('tsec');
      if (!tsec) {
        const params = {
          authenticationData: headers.get('authenticationdata'),
          authenticationState: data.authenticationState,
          authenticationType: headers.get('authenticationtype'),
          consumerId,
          multistepProcessId: data.multistepProcessId,
          username
        };
        throw new _loginGoOn.default(params);
      }
      const model = this._createSessionModel(app, data.user.id, Object.assign({
        app,
        consumerId,
        tsec,
        username,
        contactId: contactIdHeader.ContactId,
        authenticationType
      }, data.user));
      (false && !(model instanceof _base.default) && (0, _debug.assert)("Model ".concat(app, " must extend 'SessionBaseModel' from @woody-lite/core-session"), model instanceof _base.default));
      return model;
    }
    async loginWithOAuth(_ref3) {
      let {
        app
      } = _ref3;
      const {
        consumerId,
        userId,
        M1,
        M2
      } = this._getConfig(app);
      const authenticationType = AUTHENTICATION_TYPES.emulation;
      const device = await this.provider.pull('device', 'getDevice');
      if ((0, _object.get)(device, 'isNative')) {
        const data = {
          scope: NATIVE_SCOPES.PUBLIC,
          app: NATIVE_APPS.WOODY,
          userId: '',
          password: ''
        };
        await this.slinky.send('Session.invokeGrantingTicket', data);
        const publicSession = await this.provider.fetch('slinky', 'Session.getData');
        const options = {
          app,
          userId,
          consumerId: (0, _object.get)(publicSession, 'aap'),
          access_token: (0, _object.get)(publicSession, 'tsec'),
          authenticationType
        };
        return this._setPublicSession(options);
      }
      const agent = await this.enax.POST('granting-tickets-oauth', null, {
        userId,
        M1,
        M2
      });
      const {
        data: {
          access_token
        }
      } = agent;
      const options = {
        app,
        userId,
        consumerId,
        access_token,
        authenticationType
      };
      return this._setPublicSession(options);
    }
    async loginWithPublicTsec(_ref4) {
      let {
        app
      } = _ref4;
      const {
        consumerId,
        userId
      } = this._getConfig(app);
      const authenticationType = AUTHENTICATION_TYPES.public_tsec;
      const device = await this.provider.pull('device', 'getDevice');
      if ((0, _object.get)(device, 'isNative')) {
        const data = {
          scope: NATIVE_SCOPES.PUBLIC,
          app: NATIVE_APPS.WOODY,
          userId: '',
          password: ''
        };
        await this.slinky.send('Session.invokeGrantingTicket', data);
        const publicSession = await this.provider.fetch('slinky', 'Session.getData');
        const options = {
          app,
          userId,
          consumerId: (0, _object.get)(publicSession, 'aap'),
          access_token: (0, _object.get)(publicSession, 'tsec'),
          authenticationType
        };
        return this._setPublicSession(options);
      }
      const contactIdHeader = await this.getContactId();
      const payload = {
        authentication: {
          consumerID: consumerId,
          authenticationType
        }
      };
      const agent = await this.enax.POST('granting-tickets', payload, {
        headers: contactIdHeader
      });
      const {
        response: {
          headers
        }
      } = agent;
      const tsec = headers.get('tsec');
      const options = {
        app,
        userId,
        consumerId,
        access_token: tsec,
        authenticationType
      };
      return this._setPublicSession(options);
    }
    async loginWithEmulatedUser(_ref5) {
      let {
        username,
        password,
        app,
        userId,
        token
      } = _ref5;
      const authenticationType = AUTHENTICATION_TYPES.emulation;
      const {
        emulationConsumerId,
        consumerId
      } = this._getConfig(app);
      const payload = {
        authentication: {
          consumerID: emulationConsumerId,
          authenticationData: [{
            authenticationData: [password],
            idAuthenticationData: 'password'
          }, {
            authenticationData: [token],
            idAuthenticationData: 'otp-emulacion'
          }, {
            authenticationData: [username],
            idAuthenticationData: 'usuario-cliente'
          }],
          authenticationType,
          userID: userId.toUpperCase()
        }
      };
      const contactIdHeader = await this.getContactId();
      const agent = await this.enax.POST('granting-tickets', payload, {
        headers: contactIdHeader
      });
      const {
        data,
        response: {
          headers
        }
      } = agent;
      const tsec = headers.get('tsec');
      if (!tsec) {
        const params = {
          authenticationData: headers.get('authenticationdata'),
          authenticationState: data.authenticationState,
          authenticationType: headers.get('authenticationtype'),
          consumerId,
          multistepProcessId: data.multistepProcessId,
          username
        };
        throw new _loginGoOn.default(params);
      }
      const model = this._createSessionModel(app, data.user.id, Object.assign({
        app,
        consumerId,
        tsec,
        username,
        contactId: contactIdHeader.ContactId,
        authenticationType
      }, data.user));
      (false && !(model instanceof _base.default) && (0, _debug.assert)("Model ".concat(app, " must extend 'SessionBaseModel' from @woody-lite/core-session"), model instanceof _base.default));
      return model;
    }
    async changePassword(_ref6) {
      let {
        consumerID,
        password,
        userID,
        multistepProcessId
      } = _ref6;
      const payload = {
        authentication: {
          consumerID,
          authenticationData: [{
            authenticationData: [password],
            idAuthenticationData: 'newpassword'
          }],
          authenticationType: AUTHENTICATION_TYPES.password,
          userID: userID.toUpperCase(),
          multistepProcessId
        }
      };
      const contactIdHeader = await this.getContactId();
      return this.enax.POST('granting-tickets', payload, {
        headers: contactIdHeader
      });
    }
    async recoverSession(options) {
      const device = await this.provider.pull('device', 'getDevice');
      if ((0, _object.get)(device, 'isNative')) {
        return this._recoverNativeSession(options);
      }
      return this._recoverSession(options);
    }
    async logout(session) {
      var _window$HtmlSessionId;
      const device = await this.provider.pull('device', 'getDevice');
      if ((0, _object.get)(device, 'isNative')) {
        await this.slinky.send('Session.close');
      } else {
        const tsec = (0, _object.get)(session, 'tsec');
        await this.enax.DELETE('granting-tickets', null, {
          headers: {
            tsec
          }
        }).catch(() => {});
        (0, _object.set)(session, 'tsec', null);
      }
      await this.sessionStorage.removeItem("session-".concat((0, _object.get)(session, 'app')));
      await ((_window$HtmlSessionId = window.HtmlSessionId) === null || _window$HtmlSessionId === void 0 ? void 0 : _window$HtmlSessionId.refresh());
      return this.deleteEntry(session);
    }
    _setPublicSession(_ref7) {
      let {
        app,
        userId,
        consumerId,
        access_token,
        authenticationType
      } = _ref7;
      return this._createSessionModel(app, userId, {
        app,
        consumerId,
        contactId: (0, _guid.default)(),
        tsec: access_token,
        username: userId,
        authenticationType
      });
    }
    _createSessionModel(app, id, data) {
      var _this$router$_router, _routerMicroLib$activ;
      const modelName = getModelByApp(app);
      const sessionModel = this.createEntry(modelName, id, data);
      const routerMicroLib = (_this$router$_router = this.router._router) === null || _this$router$_router === void 0 || (_this$router$_router = _this$router$_router.currentState) === null || _this$router$_router === void 0 ? void 0 : _this$router$_router.router;

      // Do not delete: This action makes it possible to activate Semaas in the public environment
      if (routerMicroLib && !(routerMicroLib !== null && routerMicroLib !== void 0 && (_routerMicroLib$activ = routerMicroLib.activeTransition) !== null && _routerMicroLib$activ !== void 0 && _routerMicroLib$activ.isActive)) {
        this.router._router.send('createSession', sessionModel);
      }
      return sessionModel;
    }
    _getConfig(appName) {
      const config = (0, _application.getOwner)(this).resolveRegistration('config:environment');
      const app = config.session[appName];
      (false && !(app) && (0, _debug.assert)("App '".concat(app, "' is not defined in config environment"), app));
      (false && !(app.consumerId) && (0, _debug.assert)("App '".concat(app, "' does not contains a valid 'consumerId' property"), app.consumerId));
      return app;
    }
    async _recoverSession(_ref8) {
      let {
        app
      } = _ref8;
      const session = await this.sessionStorage.getItem("session-".concat(app));
      if (!session) {
        return null;
      }
      return this._createSessionModel(app, session.id, session);
    }
    async _recoverNativeSession(_ref9) {
      let {
        app
      } = _ref9;
      const session = await this.provider.pull('slinky', 'Session.getData');
      if ((0, _object.get)(session, 'scope') === NATIVE_SCOPES.PUBLIC) {
        // TODO: create public session here.
        return null;
      }
      const pair = NATIVE_PAIRS[app];
      if (!pair || pair.app !== (0, _object.get)(session, 'app')) {
        return null;
      }
      const response = await this.provider.pull('slinky', 'Session.getGrantingTicketResponse');
      const {
        headers: {
          tsec
        },
        result: {
          user,
          multistepProcessId
        }
      } = (0, _object.getProperties)(response, 'headers', 'result');
      if (!tsec && !multistepProcessId || !user.id) {
        return null;
      }
      const contactId = await this._getContactId();
      return this._createSessionModel(app, user.id, Object.assign({
        app,
        consumerId: (0, _object.get)(session, 'aap'),
        tsec,
        contactId,
        username: user.alias
      }, user));
    }
    async storeSession(session) {
      const device = await this.provider.pull('device', 'getDevice');
      if (device.isDestroying || device.isDestroyed) {
        return;
      }
      if ((0, _object.get)(device, 'isNative')) {
        const stored = await this.provider.pull('slinky', 'Session.getData');
        this._userCustomizationData(session);
        if (!(0, _object.get)(session, 'tsec') || (0, _object.get)(stored, 'tsec') === (0, _object.get)(session, 'tsec')) {
          return;
        }
        const pair = NATIVE_PAIRS[(0, _object.get)(session, 'app')];
        stored.unsync();
        await this.provider.pull('slinky', 'Session.setData', {
          aap: (0, _object.get)(session, 'consumerId'),
          app: pair.app,
          scope: pair.scope,
          tsec: (0, _object.get)(session, 'tsec')
        });
      } else {
        await this.sessionStorage.setItem("session-".concat((0, _object.get)(session, 'app')), session.toJSON());
      }
    }
    async _userCustomizationData(session) {
      const endDoc = (0, _object.get)(session, 'endDoc');
      const document = (0, _identificationUnformatter.default)((0, _object.get)(session, 'username'));
      await this.nativeStorage.setItem('userCustomizationData', {
        data: {
          endDoc,
          document,
          language: this.intl.primaryLocale
        }
      });
    }
    async serviceUnavailable() {
      try {
        const publicConfig = await this.provider.pull('configuration', 'getPublicConfiguration');
        const message = (0, _object.get)(publicConfig, 'caudalimeterMessage');
        const servicesCaudalimeter = (0, _object.get)(publicConfig, 'servicesCaudalimeter');
        if (this.slinky.isConnected && servicesCaudalimeter !== null && servicesCaudalimeter !== void 0 && servicesCaudalimeter.isActive) {
          this.slinky.send('InitialMessage.open');
        } else {
          this.globalContext.trigger('showInitialMessage', message);
        }
      } catch {
        //
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "enax", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "globalContext", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "nativeStorage", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "provider", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "sessionStorage", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "slinky", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "contactId", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "retry", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _class);
});