define("@woody-mrs-potato/addon-ember-alerter/components/alert-item", ["exports", "@ember/component", "@ember/service", "@ember/object", "@ember/runloop", "@ember/object/observers"], function (_exports, _component, _service, _object, _runloop, _observers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let AlertItemComponent = _exports.default = (_class = class AlertItemComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "alerter", _descriptor, this);
      (0, _observers.addObserver)(this, 'model.isShown', this.isShowDidChange);
    }
    willDestroy() {
      super.willDestroy(...arguments);
      (0, _observers.removeObserver)(this, 'model.isShown', this.isShowDidChange);
    }
    isShowDidChange() {
      var _this$model, _this$model2, _this$model3;
      const alertShow = (_this$model = this.model) === null || _this$model === void 0 ? void 0 : _this$model.isShown;
      const wasShown = (_this$model2 = this.model) === null || _this$model2 === void 0 ? void 0 : _this$model2.wasShown;
      const duration = (_this$model3 = this.model) === null || _this$model3 === void 0 ? void 0 : _this$model3.duration;

      // Update last value.
      if (this.model) {
        this.model.wasShown = alertShow;
      }
      if (alertShow && !wasShown) {
        // If is shown, run timer to not show.
        if (!this.model.isPermanent) {
          (0, _runloop.later)(this, () => {
            /* istanbul ignore else  */
            if (!this.isDestroyed) {
              (0, _object.set)(this.model, 'isShown', false);
            }
          }, duration);
        }
      } else if (wasShown) {
        // If is not shown anymore, run timer to remove it from parent.
        (0, _runloop.later)(this, () => {
          if (!this.isDestroyed) {
            this.alerter.clear(this.model);
          }
        }, 500);
      }
    }
    click() {
      if (this.model.isShown) {
        (0, _object.set)(this.model, 'isShown', false);
      }
    }
    didReceiveAttrs() {
      super.didReceiveAttrs(...arguments);

      // If is first time show alert.
      (0, _runloop.later)(this, () => {
        if (!this.isDestroyed && this.model) {
          (0, _object.set)(this.model, 'isShown', true);
        }
      }, 50);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "alerter", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
});