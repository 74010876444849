define("@woody-mrs-potato/addon-slinky/wires/ios", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function isPredicate(predicate) {
    return (predicate === null || predicate === void 0 ? void 0 : predicate.postMessage) !== undefined;
  }
  class IOSWire {
    constructor() {
      _defineProperty(this, "isEnabled", Boolean(window.webkitSlinkyInterface));
    }
    send(event, message) {
      var _window;
      if (!this.isEnabled) {
        throw new Error('IOS wire interface does not exist');
      }
      const predicate = (_window = window) === null || _window === void 0 || (_window = _window.webkit) === null || _window === void 0 || (_window = _window.messageHandlers) === null || _window === void 0 ? void 0 : _window[event];
      if (!predicate || !isPredicate(predicate)) {
        throw new Error("Event ".concat(event, " does not exist on IOS wire interface implementation"));
      }
      predicate.postMessage(message);
    }
  }
  _exports.default = IOSWire;
});