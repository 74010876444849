define("@woody-lite/core-session/templates/modal-help-initial-message", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <Helps::HelpModal
  	@icon={{true}}
  	@title={{this.model.options.title}}
  	@description={{this.model.options.message}}
  	@iconType="info"
  >
  	<:button>
  		<Buttons::ButtonDefault @text={{t "label.ok"}} @onClick={{this.resolve}} />
  	</:button>
  </Helps::HelpModal>
  */
  {
    "id": "VIGCaVx+",
    "block": "[[[8,[39,0],null,[[\"@icon\",\"@title\",\"@description\",\"@iconType\"],[true,[30,0,[\"model\",\"options\",\"title\"]],[30,0,[\"model\",\"options\",\"message\"]],\"info\"]],[[\"button\"],[[[[1,\"\\n\\t\\t\"],[8,[39,2],null,[[\"@text\",\"@onClick\"],[[28,[37,3],[\"label.ok\"],null],[30,0,[\"resolve\"]]]],null],[1,\"\\n\\t\"]],[]]]]]],[],false,[\"helps/help-modal\",\":button\",\"buttons/button-default\",\"t\"]]",
    "moduleName": "@woody-lite/core-session/templates/modal-help-initial-message.hbs",
    "isStrictMode": false
  });
});