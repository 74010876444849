define("@woody-lite/core-traceability/services/metrics", ["exports", "@ember/service", "@ember/debug", "@ember/string", "@ember/application"], function (_exports, _service, _debug, _string, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function makeArray(maybeArray) {
    return Array(maybeArray);
  }
  class Metrics extends _service.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "_adapters", {});
      _defineProperty(this, "appEnvironment", null);
      _defineProperty(this, "context", {});
      const owner = (0, _application.getOwner)(this);
      const config = owner.factoryFor('config:environment').class;
      owner.registerOptionsForType('metrics-adapter', {
        instantiate: false
      });
      const {
        environment,
        metricsAdapters
      } = config;
      this._options = {
        metricsAdapters,
        environment
      };
      this.appEnvironment = environment;
      this.activateAdapters(this._options.metricsAdapters);
    }
    activateAdapters(adapterOptions) {
      const adaptersForEnv = this._adaptersForEnv(adapterOptions);
      const activeAdapters = {};
      for (const {
        name,
        config
      } of adaptersForEnv) {
        const adapterClass = this._lookupAdapter(name);
        activeAdapters[name] = this._activateAdapter({
          adapterClass,
          config
        });
      }
      this._adapters = activeAdapters;
      return this._adapters;
    }
    _adaptersForEnv() {
      let adapterOptions = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      return adapterOptions.filter(_ref => {
        let {
          environments
        } = _ref;
        return environments.includes('all') || environments.includes(this.appEnvironment);
      });
    }
    _lookupAdapter(adapterName) {
      (false && !(adapterName) && (0, _debug.assert)('[ember-traceability] Could not find metrics adapter without a name.', adapterName));
      const localAdapter = (0, _application.getOwner)(this).lookup("metrics-adapter:".concat((0, _string.dasherize)(adapterName)));
      (false && !(localAdapter) && (0, _debug.assert)("[ember-traceability] Could not find metrics adapter ".concat(adapterName, "."), localAdapter));
      return localAdapter;
    }
    _activateAdapter(_ref2) {
      let {
        adapterClass,
        config
      } = _ref2;
      const decoratedClass = class decoratedClass extends adapterClass {
        constructor() {
          super(...arguments);
          _defineProperty(this, "trackEvent", this.trackEvent);
        }
      };
      return new decoratedClass(config, (0, _application.getOwner)(this));
    }
    trackEvent() {
      this.invoke('trackEvent', ...arguments);
    }
    append() {
      this.invoke('append', ...arguments);
    }
    invoke(methodName) {
      for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }
      const selectedAdapterNames = makeArray(args === null || args === void 0 ? void 0 : args[0]);
      const options = args === null || args === void 0 ? void 0 : args[1];
      for (const adapterName of selectedAdapterNames) {
        const adapter = this._adapters[adapterName];

        // eslint-disable-next-line @woody-mrs-potato/bbva/inline-conditional
        adapter && adapter[methodName]({
          ...this.context,
          ...options
        });
      }
    }
    willDestroy() {
      Object.values(this._adapters).forEach(adapter => adapter.destroy());
    }
  }
  _exports.default = Metrics;
});