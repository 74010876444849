define("@woody-lite/core-session/utils/format-identification", ["exports", "ember-utils/utils/banking/identification-formatter"], function (_exports, _identificationFormatter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  const PT_PREFIX = '91374900';
  const PT_IDENTIFICATOR_MAX_LENGTH = 8;
  function _identificationES(identificator) {
    return (0, _identificationFormatter.default)(identificator);
  }
  function _identificationPT(identificator) {
    const paddedIdentificator = identificator.padStart(PT_IDENTIFICATOR_MAX_LENGTH, '0');
    return "".concat(PT_PREFIX).concat(paddedIdentificator);
  }
  function _identificationIT(identificator) {
    return identificator;
  }
  function _default(identificator, app) {
    if (!identificator) {
      return null;
    }
    if (app === 'es') {
      return _identificationES(identificator);
    }
    if (app === 'pt') {
      return _identificationPT(identificator);
    }
    if (['it', 'de'].includes(app)) {
      return _identificationIT(identificator);
    }
    return identificator;
  }
});