define("@woody-lite/core-traceability/instance-initializers/tealium-multistep-tracking", ["exports", "@woody-lite/core-traceability/utils/is-adapter-enabled", "@woody-lite/core-traceability/utils/tealium-context", "ember-copy", "@woody-lite/core-traceability/events/view", "@ember/runloop", "ember-utils/utils/primitive/merge"], function (_exports, _isAdapterEnabled, _tealiumContext, _emberCopy, _view, _runloop, _merge) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function getCustomStepNumber(stepNumber, stepId) {
    return stepId === 'resumen' ? stepNumber + 1 : stepNumber;
  }
  function handleTrackingMultistepData(ev) {
    (0, _runloop.next)(() => {
      const {
        stepNumber,
        trackingData,
        stepId
      } = ev.detail;
      if (typeof trackingData === 'boolean' && trackingData === false) {
        return;
      }
      const step = getCustomStepNumber(stepNumber, stepId);
      const state = "app step ".concat(step);
      const page = (0, _tealiumContext.getContext)(this, 'currentRoute.page') || {};
      if (page !== null && page !== void 0 && page.pageInfo) {
        var _page$pageInfo;
        page.pageInfo.pageName = "".concat(page === null || page === void 0 || (_page$pageInfo = page.pageInfo) === null || _page$pageInfo === void 0 ? void 0 : _page$pageInfo.pageName, ":").concat(step);
      }
      const data = (0, _emberCopy.copy)(trackingData, true);
      const autoEvent = {
        application: {
          state,
          step
        },
        page
      };
      const eventData = typeof trackingData === 'object' ? (0, _merge.default)(autoEvent, data) : autoEvent;
      this.trackEvent('tealium', new _view.default(eventData));
    });
  }
  async function initialize(instance) {
    const config = instance.resolveRegistration('config:environment');
    if ((0, _isAdapterEnabled.default)(config)) {
      const metrics = instance.lookup('service:metrics');
      if (metrics && !metrics._multistepTrackingInitialized) {
        document.addEventListener('tracking-multistep-data', handleTrackingMultistepData.bind(metrics));
        metrics._multistepTrackingInitialized = true;
      }
    }
  }
  var _default = _exports.default = {
    name: 'tealium/multistep-tracking',
    initialize
  };
});